import styled from 'styled-components';

import { hexToRGBA } from '@xemplo/color-util';
import {
  BodySmallMedium,
  BodySmallRegular,
  BodyStandardMedium,
  Colour,
  HeadingStandardMedium,
  MinBreakpoint,
  TextEllipsis,
} from '@xemplo/style-constants';

import { ModalBodyOverflow } from '../body';

export const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  box-sizing: border-box;

  display: flex;
  gap: 24px;
  align-items: center;
  transition: box-shadow 0.2s ease-out;
  padding: 24px 24px 20px 24px;

  > .header-btn {
    margin-left: auto;
    margin-right: 26px;
    @media ${MinBreakpoint.medium} {
      margin-right: 0;
      align-self: flex-end;
    }
  }

  @media ${MinBreakpoint.medium} {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 44px 44px 32px 44px;
  }

  &[data-overflow=${ModalBodyOverflow.Extended}] {
    position: static;
  }

  &.can-show-keyline {
    border-bottom: 1px solid ${Colour.Gray[200]};
  }

  &.can-show-shadow {
    box-shadow: 0px 4px 8px ${hexToRGBA(Colour.Gray[200], 0.4)};
  }
`;

/**
 * NOTE: About the -webkit-line-clamp property
 * https://css-tricks.com/almanac/properties/l/line-clamp/
 */
export const StyledTitles = styled.div`
  display: flex;
  gap: 4px;
  flex: 1;
  > * {
    ${TextEllipsis};
    white-space: initial;
    transition: all 0.2s ease-out;
  }

  > .pre-title,
  .descriptor {
    display: none;
    color: ${Colour.Gray[500]};

    @media ${MinBreakpoint.medium} {
      display: block;
    }
  }

  > .pre-title {
    ${BodySmallMedium};
  }

  > .title {
    ${BodyStandardMedium};
    color: ${Colour.Gray[800]};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media ${MinBreakpoint.medium} {
      ${HeadingStandardMedium};
      -webkit-line-clamp: 1;
    }
  }

  > .descriptor {
    ${BodySmallRegular};
  }

  @media ${MinBreakpoint.medium} {
    flex-direction: column;
  }
`;
