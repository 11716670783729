import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useResubmitPayrunAmendment } from '@xemplo/amendment-query';
import { ButtonType } from '@xemplo/button';
import { HeaderIconMode } from '@xemplo/header-icon';
import { DawnTriangleAlert } from '@xemplo/icons';
import { ModalBodyOverflow, ModalTheme } from '@xemplo/modal';
import { PromptProps, PromptType } from '@xemplo/prompts';
import { ToastType, useToast } from '@xemplo/toast';

import * as S from './payrun-resubmit-amendment.styles';
import { PayrunResubmitAmendmentProps } from './payrun-resubmit-amendment.types';
import { ResubmitReason } from './resubmit-reason';

export const useResubmitPrompt = (props: PayrunResubmitAmendmentProps) => {
  const { amendment, onSuccess } = props;
  const [promptVisible, setPromptVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addToast } = useToast();
  const { id } = useParams();

  const { mutateAsync: resubmitAmendment } = useResubmitPayrunAmendment({
    payrunId: id ?? amendment.payrunId ?? null,
    amendmentId: amendment.amendmentId ?? null,
    onSuccess: () => {
      setPromptVisible(false);
      addToast({
        text: 'Amendment resubmitted',
        type: ToastType.Confirmation,
      });
      onSuccess?.();
    },
    onError: (error) => {
      const errorMessage = error.message ?? 'Something went wrong, please try again.';
      setErrorMessage(errorMessage);
    },
  });
  const [payload, setPayload] = useState<string>('');

  //config for prompt modal
  const initialPromptProps = useMemo<PromptProps>(() => {
    return {
      open: false,
      type: PromptType.General,
      theme: ModalTheme.Light,
      header: {
        title: 'Resubmit amendment',
        headerIcon: {
          icon: <DawnTriangleAlert />,
          mode: HeaderIconMode.Worker,
        },
      },
      body: {
        content: (
          <S.PromptBody>
            <div>Are you sure you want to resubmit this amendment? </div>
            <ResubmitReason setPayload={setPayload} />
          </S.PromptBody>
        ),
        overflow: ModalBodyOverflow.Extended,
      },
      footer: {
        btnPrimary: {
          label: 'Resubmit',
          ariaLabel: 'Resubmit button',
          onClickAsync: () => resubmitAmendment({ description: payload }),
          type: ButtonType.Primary,
        },
        btnSecondary: {
          label: 'Cancel',
          ariaLabel: 'Cancel button',
          type: ButtonType.Secondary,
          onClick: () => setPromptVisible(false),
        },
      },
    };
  }, [resubmitAmendment, payload]);

  const [promptProps, setPromptProps] = useState<PromptProps>(initialPromptProps);

  useEffect(() => {
    if (errorMessage) {
      setPromptProps({
        ...initialPromptProps,
        body: {
          content: (
            <>
              <S.PromptBody>
                Are you sure you want to resubmit amendment? You can't undo changes.
              </S.PromptBody>
              <S.ErrorMessage>
                <DawnTriangleAlert />
                {errorMessage}
              </S.ErrorMessage>
            </>
          ),
          overflow: ModalBodyOverflow.Extended,
        },
      });
    } else {
      setPromptProps(initialPromptProps);
    }
  }, [errorMessage, initialPromptProps]);

  useEffect(() => {
    if (initialPromptProps.open) {
      setPromptVisible(true);
    }
  }, [initialPromptProps.open]);

  return { promptProps, promptVisible, setPromptVisible };
};
