import { forwardRef } from 'react';
import { FormProvider } from 'react-hook-form';

import { AmendmentStatus } from '@xemplo/amendment-status';
import { DawnCircleInformation } from '@xemplo/icons';
import { InlineBanner, InlineBannerType } from '@xemplo/inline-banner';
import { Colour } from '@xemplo/style-constants';
import { TextAreaV2 } from '@xemplo/text-area';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';
import { TextFieldV2 } from '@xemplo/text-field';
import { Tooltip, TooltipPlacement } from '@xemplo/tooltip';

import {
  PLACEHOLDER_OPTION,
  taxCalcOptions,
  YesNoOptions,
} from './edit-amendment-form.helper';
import * as S from './edit-amendment-form.styles';
import { EditAmendmentProps, UseFormRef } from './edit-amendment-form.types';
import { useEditAmendmentForm } from './use-edit-amendment-form';

export const EditAmendmentForm = forwardRef<UseFormRef, EditAmendmentProps>(
  (props: EditAmendmentProps, ref) => {
    const {
      methods,
      showLumpSumOptions,
      showLumpSumCalculationMethod,
      showPaymentPeriods,
      displayWorkerName,
      requestedByName,
      lumpSumValue,
      amendmentStatusID,
      amendmentStatusName,
      amendmentTypeName,
      amendmentTaskTypeName,
      amendmentSubTypeName,
      lumpSumCalculationMethod,
      units,
      amount,
    } = useEditAmendmentForm({ ...props, ref });

    const customRequired = (msg: string) => (value: string) =>
      value === '-1' ? msg : true;

    return (
      <FormProvider {...methods}>
        <S.StyledForm>
          <>
            <AmendmentStatus
              status={amendmentStatusID ?? 0}
              statusName={amendmentStatusName ?? ''}
            />
            <S.AmendmentType>{amendmentTypeName}</S.AmendmentType>
            <S.DetailRow>
              Type <div>{amendmentTaskTypeName}</div>
            </S.DetailRow>
            <S.DetailRow>
              Sub type<div>{amendmentSubTypeName}</div>
            </S.DetailRow>
            <S.DetailRow>
              Applies to<div>{displayWorkerName}</div>
            </S.DetailRow>
            <S.DetailRow>
              Requested by<div>{requestedByName}</div>
            </S.DetailRow>
          </>

          {/** Rate */}
          <S.FormLabel>
            <S.LabelWithTooltip>
              <span>Rate</span>
              <S.OptionalLabel>(optional)</S.OptionalLabel>
              <Tooltip
                text="If no rate is specified, the worker’s rate from PPS will be used."
                placement={TooltipPlacement.BottomRight}
              >
                <DawnCircleInformation height={24} width={24} color={Colour.Gray[300]} />
              </Tooltip>
            </S.LabelWithTooltip>
            <TextFieldV2
              testId="rate"
              aria-label="Rate field"
              name="rate"
              defaultValue={amount?.toString()}
              placeholder="Rate"
              type="number"
            />
          </S.FormLabel>
          {/** Units */}
          <S.FormLabel>
            <span>Units</span>
            <TextFieldV2
              testId="units"
              aria-label="Units field"
              name="units"
              placeholder="Units"
              type="number"
              defaultValue={units?.toString()}
              rules={{
                required: 'Units field is required',
              }}
            />
          </S.FormLabel>
          {showLumpSumOptions && (
            <>
              {/** Lump Sum Payments */}
              <S.FormLabel className="full-width">
                Lump Sum Payments
                <S.LabelExtraInfo>
                  Payments for which the PAYG obligation is spread across multiple pay
                  periods. Refer to ATO Schedule 5 - Tax Table for Back Payments,
                  Commissions, Bonuses and Similar Payments
                </S.LabelExtraInfo>
                <TextDropdownMultiV2
                  name="lumpSum"
                  items={YesNoOptions}
                  isDropdownMulti={false}
                  defaultSelectedKeys={[lumpSumValue]}
                  testId="lumpSum"
                  aria-label="Lump sum field"
                  rules={{
                    validate: customRequired('Lump Sum Payments field is required'),
                  }}
                  selectProps={{
                    placeholder: 'Select an option',
                  }}
                />
              </S.FormLabel>
              {/** Tax Calculation Method */}
              {showLumpSumCalculationMethod && (
                <S.FormLabel className="full-width">
                  Tax calculation method
                  <TextDropdownMultiV2
                    name="lumpSumCalculationMethod"
                    items={taxCalcOptions}
                    isDropdownMulti={false}
                    testId="lumpSumCalculationMethod"
                    aria-label="Tax calculation method field"
                    rules={{
                      validate: customRequired(
                        'Tax Calculation Methods field is required'
                      ),
                      shouldUnregister: true,
                    }}
                    defaultSelectedKeys={
                      lumpSumCalculationMethod
                        ? [lumpSumCalculationMethod]
                        : [PLACEHOLDER_OPTION]
                    }
                    selectProps={{
                      placeholder: 'Select method',
                    }}
                  />
                </S.FormLabel>
              )}

              {/** Payment periods */}
              {showPaymentPeriods && (
                <S.FormLabel className="full-width">
                  Payment Periods
                  <TextFieldV2
                    name="lumpSumNumberOfPayPeriods"
                    placeholder="Enter payment periods"
                    aria-label="Payment periods field"
                    testId="paymentPeriods"
                    type="number"
                    min={1}
                    rules={{
                      validate: customRequired('Payment Periods field is required'),
                      shouldUnregister: true,
                      required: 'Payment Periods field is required',
                    }}
                  />
                </S.FormLabel>
              )}
            </>
          )}
          <S.FormLabel className="full-width">
            <span>
              Notes
              <S.OptionalLabel> (optional)</S.OptionalLabel>
            </span>
            <TextAreaV2
              placeholder="Enter notes"
              testId="notes"
              aria-label="Notes field"
              name="note"
              stretch
              rows={4}
            />
          </S.FormLabel>
          {props.error && (
            <S.FormLabel className="full-width">
              <InlineBanner type={InlineBannerType.Alert} subTitle={props.error ?? ''} />
            </S.FormLabel>
          )}
        </S.StyledForm>
      </FormProvider>
    );
  }
);

export default EditAmendmentForm;
