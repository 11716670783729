import { IconGradient } from '@xemplo/icons';

import * as S from './header-icon.styles';
import { HeaderIconProps } from './header-icon.types';

export const HeaderIcon = ({ icon, mode }: HeaderIconProps) => {
  return (
    <S.StyledIconContainer>
      <S.StyledHeaderIconWrapper data-mode={mode} className="header-icon">
        {icon}
      </S.StyledHeaderIconWrapper>
      <IconGradient className="icon-gradient" />
    </S.StyledIconContainer>
  );
};
