import styled from 'styled-components';

import { Colour, MinBreakpoint, Shadow } from '@xemplo/style-constants';

import { HeaderIconMode } from './header-icon.types';

export const StyledIconContainer = styled.div`
  display: none;

  @media (min-width: 720px) {
    display: flex;
    width: 56px;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
  }
`;

export const StyledHeaderIconWrapper = styled.span`
  display: none;
  flex-shrink: 0;

  + .icon-gradient {
    display: none;
  }

  &[data-mode=${HeaderIconMode.Admin}] {
    background: ${Colour.White[100]};
    ${Shadow.Medium2};
    color: ${Colour.Gray[800]};

    & path {
      fill: url(#primaryGradient);
    }
  }

  &[data-mode=${HeaderIconMode.Worker}] {
    background: linear-gradient(135deg, #3991ff 0%, #a48afb 50.15%);
    color: ${Colour.White[100]};
    ${Shadow.Medium2};
  }

  &[data-mode=${HeaderIconMode.Shadowed}] {
    border: 2px solid ${Colour.White[100]};
    border-radius: 50%;
    ${Shadow.Medium1};
  }

  @media ${MinBreakpoint.medium} {
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    width: 56px;
    height: 56px;

    border-radius: 12px;
    transition: all 0.2s ease-out;

    & > svg {
      width: 32px;
      height: 32px;
      transition: all 0.2s ease-out;
    }
  }
`;
