import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';

import { ButtonSize, ButtonWithState } from '@xemplo/button';
import { HeaderIcon } from '@xemplo/header-icon';

import { ModalBodyOverflow } from '../body';
import { useModal } from '../hooks';

import { StyledHeader, StyledTitles } from './header.style';

export const ModalHeaderTestId = {
  header: 'modal-header',
  icon: 'modal-icon',
  preTitle: 'modal-preTitle',
  title: 'modal-title',
  descriptor: 'modal-descriptor',
  headerBtn: 'modal-header-btn',
};

export const ModalHeader = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { state, titleRef } = useModal();
  const { header, body } = state;

  if (!header) return null;
  const {
    headerIcon: icon,
    preTitle,
    title,
    descriptor,
    headerBtn,
    customHeader,
    showKeyline = true,
  } = header;
  return (
    customHeader ?? (
      <StyledHeader
        ref={ref}
        data-testid={ModalHeaderTestId.header}
        data-overflow={body?.overflow ?? ModalBodyOverflow.Standard}
        className={classNames({ 'can-show-keyline': showKeyline })}
      >
        {icon && <HeaderIcon {...icon} data-testid={ModalHeaderTestId.icon} />}
        <StyledTitles>
          {preTitle && (
            <span className="pre-title" data-testid={ModalHeaderTestId.preTitle}>
              {preTitle}
            </span>
          )}
          {title && (
            <span className="title" data-testid={ModalHeaderTestId.title} ref={titleRef}>
              {title}
            </span>
          )}
          {descriptor && (
            <span className="descriptor" data-testid={ModalHeaderTestId.descriptor}>
              {descriptor}
            </span>
          )}
        </StyledTitles>
        {headerBtn && (
          <ButtonWithState
            {...headerBtn}
            size={ButtonSize.Small}
            className="header-btn"
            testId={ModalHeaderTestId.headerBtn}
          >
            {headerBtn.label}
          </ButtonWithState>
        )}
      </StyledHeader>
    )
  );
});
